import React from "react";
import { Link } from "gatsby";

const Pagination = ({path, currentPage, numPages}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = (currentPage - 1) === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  path = path.substr(0, path.split('/', 2).join('/').length)

  return (
    <>
      {numPages > 1 && (
        <nav className="pagination flex justify-center items-center mt-8" role="navigation" aria-label="pagination">
          <Link
              className={`pagination-link pagination-previous${isFirst ? ` invisible` : ``}`}
              to={`${path}/${prevPage}`}
              rel="prev"
          >
            <i className="fas fa-arrow-left"><span className="hidden">Page précédente</span></i>
          </Link>
          <div className="pagination-link pagination-list">
            {currentPage} / {numPages}
          </div>
          <Link
              className={`pagination-link pagination-next${isLast ? ` invisible` : ``}`}
              to={`${path}/${nextPage}`}
              rel="next"
          >
            <i className="fas fa-arrow-right"><span className="hidden">Page suivante</span></i>
          </Link>
        </nav>
      )}
    </>
  )
}

export default Pagination
